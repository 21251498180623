.header-main{
    background: $themecolor-dark;
    max-height:220px;
    .tagline{
        font-family: $headingfontalt;
        font-size: 14px;
        color:$white;
        padding: 0 0 0 16px;
        margin:0;
        display: block;
        clear: left;
    }
    .header-topbar{
        padding: 15px 0;
        color:$white;
    }
    .con-btn{
        text-align:right;
        a{
            padding: 5px 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        span {
            display: inline-block;
            padding: 5px 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .header-nav-bar{
        background: $themecolor;
        display: flex;
        box-shadow: $card-shadow;
        .logo-box{
            padding: 8px 0 10px 20px;
            flex-shrink: 0;
            width: 460px;
            border-right:1px solid $border;
            img {
                height:60px;
            }
        }
        .header-nav-box {
            display: block;
            width: calc(100% - 460px);
            // .header-mini-bar{
            //     border-bottom: 1px solid $border;
            //     .gen-info{
            //         span{
            //             display: inline-block;
            //             padding: 15px 20px;
            //             font-weight: 400;
            //         }
            //     }
            //     .social-info{
            //         padding-right: 10px;
            //         a{
            //             display: inline-block;
            //             padding: 15px 8px;
            //             font-weight: 400;
            //             color:$muted;
            //             &:hover{
            //                 color:$dark;
            //             }
            //         }
            //     }
            // }
            .main-nav{
                ul{
                    margin: 0px;
                    padding: 0px;
                    font-size: 16px;
                    // font-weight: 500;
                    font-family: $headingfont;
                    text-transform: uppercase;
                    letter-spacing:0.08em;
                    li{
                        list-style: none;
                        margin: 0px;
                        padding: 0px;
                        position: relative;
                        a{
                            padding: 42px 0px 38px;
                            color: $themecolor-alt;
                            display: block;
                            &:hover {
                                color: $white;
                            }
                        }

                    }
                    .search {
                        .nav-link{
                            background: $themecolor;
                            padding: 18px 23px;

                        }
                        .dropdown-menu{
                            padding: 20px;
                            min-width: 300px;
                        }
                    }
                }

                >ul{
                    display: table;
                    width: 100%;
                    >li{
                        display: table-cell;
                        text-align: center;
                        border-left: 1px solid $border;

                        &:first-child{
                            border-left:0px;
                        }
                        &:hover{
                            //background: $light;
                            color:$white;
                            > ul{
                                display: block;
                            }
                        }

                    }
                }
            }

        }
    }
}

@media(max-width: 1199px) {
    .header-main{
        background: $themecolor-dark;
        max-height:200px;
        .tagline{
            font-family: $headingfontalt;
            font-size: 12px;
            color:$white;
            padding: 0 0 0 12px;
            margin:0;
            display: block;
            clear: left;
        }
        .header-topbar{
            padding: 15px 0;
            color:$white;
        }
        .con-btn{
            text-align:right;
            a{
                padding: 5px 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            span {
                display: inline-block;
                padding: 5px 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
        .header-nav-bar{
            background: $themecolor;
            display: flex;
            box-shadow: $card-shadow;
            .logo-box{
                padding: 8px 0 10px 20px;
                flex-shrink: 0;
                width: 380px;
                border-right:1px solid $border;
                img {
                    height:50px;
                }
            }
            .header-nav-box {
                display: block;
                width: calc(100% - 380px);
                // .header-mini-bar{
                //     border-bottom: 1px solid $border;
                //     .gen-info{
                //         span{
                //             display: inline-block;
                //             padding: 15px 20px;
                //             font-weight: 400;
                //         }
                //     }
                //     .social-info{
                //         padding-right: 10px;
                //         a{
                //             display: inline-block;
                //             padding: 15px 8px;
                //             font-weight: 400;
                //             color:$muted;
                //             &:hover{
                //                 color:$dark;
                //             }
                //         }
                //     }
                // }
                .main-nav{
                    ul{
                        margin: 0px;
                        padding: 0px;
                        font-size: 14px;
                        // font-weight: 500;
                        font-family: $headingfont;
                        text-transform: uppercase;
                        letter-spacing:0.08em;
                        li{
                            list-style: none;
                            margin: 0px;
                            padding: 0px;
                            position: relative;
                            a{
                                padding: 42px 12px 38px;
                                color: $themecolor-alt;
                                display: block;
                                &:hover {
                                    color: $white;
                                }
                            }

                        }
                        .search {
                            .nav-link{
                                background: $themecolor;
                                padding: 18px 15px;

                            }
                            .dropdown-menu{
                                padding: 20px;
                                min-width: 300px;
                            }
                        }
                    }

                    >ul{
                        display: table;
                        width: 100%;
                        >li{
                            display: table-cell;
                            text-align: center;
                            border-left: 1px solid $border;

                            &:first-child{
                                border-left:0px;
                            }
                            &:hover{
                                //background: $light;
                                color:$white;
                                > ul{
                                    display: block;
                                }
                            }

                        }
                    }
                }

            }
        }
    }
}
@media(max-width:991px) {
    .header-main {
        .tagline{
            font-family: $lightfont;
            font-size: 10px;
            font-weight:400;
            color:$white;
            padding: 0 0 0 10px;
            margin:0;
            display: block;
            clear: left;
        }
        .con-btn{
            text-align:right;
        }
        .header-nav-bar{
            display: block;
            z-index: 100;
            position: relative;
            .logo-box{
                padding: 8px 0 10px 10px;
                flex-shrink: 0;
                width: 296px;
                border-right:1px solid $border;
                img {
                    height:40px;
                }
                display: inline-block;
            }
            .header-nav-box{
                display: none;
                width: 100%;

                &.show{
                    display: block;

                }
                .header-mini-bar{
                    border-top:1px solid $border;
                }

                .main-nav{
                    //max-height: 200px;
                    //overflow: auto;
                    >ul{
                        display: block;
                        >li{
                            display: block;
                            text-align: center;
                            a{
                                padding: 20px;
                            }
                            ul{
                                position: relative;
                                width: 100%;
                                padding-top: 0px;
                            }
                        }
                    }
                }

            }
            .op-clo {
                float: right;
                padding: 9px 12px 9px 11px;
                margin: 18px 5px 0 0;
            }
        }
    }
}
