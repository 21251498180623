@import 'variable';
.pricing1 .card {
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s; }
    .pricing1 .card:hover {
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -webkit-font-smoothing: antialiased; }

  .pricing1 .badge {
    top: -13px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto; }

  .pricing1 .title {
    margin-top: 20px;
    margin-bottom: 5px; }

  .pricing1 .subtitle {
    font-size: 14px; }

  .pricing1 .pricing {
    margin: 20px 0; }
    .pricing1 .pricing sup {
      font-size: 18px;
      top: -20px; }
    .pricing1 .pricing .display-5 {
      color: #263238; }
    .pricing1 .pricing .yearly {
      display: none; }

  .pricing1 .list-inline {
    margin-top: 30px; }
    .pricing1 .list-inline li {
      padding: 8px 0;
      display: block; }

  .pricing1 .bottom-btn {
    position: relative;
    bottom: 0px; }
    .testimonial5 .testi5 {
        position: relative; }
        .testimonial5 .testi5 .content {
          font-size: 21px;
          line-height: 30px;
          padding: 50px 30px; }
        .testimonial5 .testi5 .owl-item.center .content {
          color: #ffffff;
          background: #20d7e3;
          background: -webkit-linear-gradient(legacy-direction(to right), #20d7e3 0%, #20aee3 100%);
          background: -webkit-gradient(linear, left top, right top, from(#20d7e3), to(#20aee3));
          background: -webkit-linear-gradient(left, #20d7e3 0%, #20aee3 100%);
          background: -o-linear-gradient(left, #20d7e3 0%, #20aee3 100%);
          background: linear-gradient(to right, #20d7e3 0%, #20aee3 100%); }
        .testimonial5 .testi5 .customer-thumb {
          margin-top: -30px;
          display: none; }
          .testimonial5 .testi5 .customer-thumb img {
            width: 60px;
            margin-bottom: 20px; }
        .testimonial5 .testi5 .owl-item.center .customer-thumb {
          display: block; }
/*******************
Testimonial 3
*******************/

.testimonial3 {
    .testi3 {
        .card-body {
            padding: 40px;
        }
        h6 {
            line-height: 26px;
        }
        .thumb-img {
            img {
                width: 60px;
            }
        }
        .customer {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
