//@import '../variable';

/*******************
Feature 22
*******************/
.wrap-feature-22{
    margin-top: 60px;
    margin-bottom: 60px;
    .text-box{
        padding: 0 30px;
        h3{
            margin: 10px 0 25px 0;
        }
    }

}

/*******************
team 4
*******************/
.team4{text-align: center;
    .pro-pic{
        padding: 30px;
        padding-bottom: 0px;
        img{
            border-radius: 100%;
        }
    }
    ul{
        margin-top: 30px;
        li a{
            color:$muted;
            padding-right:15px;
            transition: 0.1s ease-in;
            display: block;
            &:hover{

                transform: translate3d(0px, -5px, 0px);
            }

        }

    }
    .title{
        margin: 30px 0 0 0;
    }
    .subtitle{
        margin: 0 0 20px 0;
        font-size: 13px;
    }
}

/*******************
Footer 4
*******************/
.footer4{
    .f4-bottom-bar{
        padding-top: 10px;
        margin-top: 20px;
        a {
            color: $bodytext;
            &:hover {
                color: $info;
            }
        }
    }
    .round-social.light {
        a {
            width: 34px;
            height: 34px;
            line-height: 34px;
            &:hover {
                background: $info;
            }
        }
    }
}
