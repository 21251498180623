.blog-home3 {
    .date-pos{
        text-align: center;
        color:$white;
        font-size: 12px;
        padding: 10px;
    }
    a{
       overflow: hidden;
        img{
            //transition: 0.2s ease-in;
            &:hover{
                //transform: scale(1.05);
            }

        }
    }
}
