/*
Template Name: Wrapkit Ui Kit
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/*==============================================================
 For Laptop & above all (1650px)
 ============================================================== */

@media(min-width:1650px) {

}


/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */

@media(max-width:1370px) {

}


/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media(min-width:1024px) {
     .page-wrapper {
        padding-top: 0px;
    }
     .hover-dropdown .navbar-nav {
        > .dropdown:hover {
            > .dropdown-menu {
                display: block;
                margin-top: 0px;

            }
        }
    }
    .navbar-nav {
        > .dropdown {
             .dropdown-menu {
                min-width: 210px;
                margin-top: 0px;
            }
        }
    }
    .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }
}


@media(max-width:1023px) {
    .d-flex{
        display: block!important;
        &.no-block{
            display: flex !important;
        }
    }
    .wrap-feature30-box{
        left:0;
        right:0;
        margin: 0 auto;
        top:50%;
    }
    .wrap-feature31-box {
        .right-image{
            position: relative;
            bottom: -95px;
            img{
                width: 100%;

            }
        }
    }
    .wrap-feature34-box {
        .left-image{
            position: relative;
            top:-90px;
        }
    }
    .po-absolute{
        position: relative;
    }
    .topbar{

        position: absolute;
        &.animated{
            animation-duration:0s;
            &.slideInDown{
                -webkit-animation-name: none;
                animation-name: none;
            }
        }
    }
    .h12-nav {
        padding: 10px 0;
        .navbar-nav .nav-item > .nav-link{
            line-height: 30px;
        }
        .navbar-nav .nav-item{
            margin: 0 10px;
        }
    }

}


/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */

@media(min-width:768px) {

}


/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */

@media(max-width:767px) {
    /*This is for the Global*/
    .b-l, .b-r{
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px solid $border;
    }
    .img-inline{
        li.half-width{
            width: 100%;
        }
    }
    .both-space{
        margin: 10px 0;
    }
    .side-content{
        padding: 40px 20px;
    }

    .general-listing.two-part{
        li{
            width: 100%;
            display: block;
        }
    }
    /*This is for the feature 12*/
    .wrap-feature-12 .uneven-box{
        margin-top: 0px;
    }
    /*This is for the feature 16*/
    .wrap-feature-16{
        .with-text{
            margin-left: 0px;
        }
    }
    /*This is for the feature 18*/
    .wrap-feature-18 .icon-position .icon-round{
        top:157px;
        right:30px;
    }
    /*This is for the feature 22*/
    .wrap-feature-22 .text-box, .wrap-feature23-box .text-box{
        padding: 20px 0px;
    }
    .wrap-feature30-box{
        position: relative;
    }
    .dropdown-submenu > .dropdown-menu.show {
        display: block;
    }
}
