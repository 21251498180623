@import 'bootstrap/bootstrap';
@import 'variable';


//@import '../css/animate.css';
@import 'icons/font-awesome/scss/font-awesome';
@import "icons/iconmind/iconmind.scss";
@import "icons/simple-line-icons/scss/simple-line-icons.scss";
//@import 'spinner';

@import 'app';
@import 'common';
@import 'responsive';
@import 'demo';
@import 'blog';
@import 'header';
@import 'footer';
@import 'pricing';
@import 'about';
@import 'authors';
@import 'search';
@import 'owl';
@import 'switch';
@import 'home';

.required:after {
    content: '*';
    font-size: 14px;
    font-weight: bold;
    color: #da5a47;
    margin: -2px 0 0 7px;
}

.video-thumbnail {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:before {
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      content: "\f01d";
      font-family: FontAwesome;
      font-size: 100px;
      color: #fff;
      opacity: .8;
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    }
    &:hover:before {
      color: #eee;
    }
  }

  ul.errors {
    list-style-type: none;
    margin: 0;
    padding:0;


      li {
        margin-top: 4px;
        margin-left: 4px;
        color: $red;
      }
  }


