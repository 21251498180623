

@import 'variable';



/*******************
This is for Header
*******************/
img.scale-with-grid {
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
}

/*******************
slide 4
*******************/
@keyframes imagescale {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
		-moz-transform: scale(1.0);
		-o-transform: scale(1.0);
    }
    100% {
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		-o-transform: scale(1.3);
    }
}

.slider4 {
	.slide-image {
		animation: imagescale 15s ease-in-out infinite alternate;
		-webkit-animation: imagescale 15s ease-in-out infinite alternate;
		-moz-animation: imagescale 15s ease-in-out infinite alternate;
		-o-webkit-animation: imagescale 15s ease-in-out infinite alternate;
	}
	.carousel-indicators {
		bottom:0;

		li {
			background-color:$white;

			&.active{
				background-color:$themecolor;
			}
		}
	}
	label {
		font-style:italic;
		font-family:'PlayFairDisplay', sans-sarif;
		-webkit-animation-delay: 0.5s;
		animation-delay: 0.5s;
	}
	ul {
		list-style:none;
		margin:0;
		-webkit-animation-delay: 1.1s;
		animation-delay: 1.1s;

		li{
			line-height:36px;
		}
	}
	h1 {
		font-size:48px;
		line-height:58px;

	}
	p {
		font-size:18px;
        color:$dark;
        margin-bottom: 35px;
	}
    .slider-control span {
		width:53px;
		height:53px;
		line-height:53px;
		text-align:center;
		border-radius:50%;
		border:1px solid $dark;
        color: $dark;
	}
	.btn-secondary {
		background:transparent;
		color:$dark;

		&:hover {
			color:$dark !important;
		}
	}
}

@media (max-width: 768px) {
	.slider4 {
		h1 {
			font-size:28px;
			line-height:36px;
		}
		.slide-text {
			padding:0 !important;
			width:95% !important;
		}
		.slide-image {
			height:480px;
            width: auto !important;
			position:relative;
		}
        p {
		display: none
	}
		.btn-md {
			padding-left:25px;
			padding-right:25px;
		}
	}
    .slider-control{
            display: none;
        }
}

@media (max-width: 428px) {
	.slider4 {
		h1 {
			font-size:23px;
			line-height:30px;
		}
		.slide-image {
			left: -50%;
			height:450px;
		}
		.btn-md {
			text-align:center;
			margin-bottom:10px;
            margin-left: 0px;
		}
		.btn-secondary {
			margin-left:0 !important;
		}

	}
}
/*******************
Feature 1
*******************/
.wrap-feature1-box .card-body {
    padding: 40px;
}
@media (max-width: 768px) {
.wrap-feature1-box .card-body {
    padding: 15px;
}
}
/*******************
Portfolio 1
*******************/
.img-ho{
    overflow:hidden;
    img{
        transition:0.1s ease-in;
        &:hover{
            transform:scale(1.1);
        }
    }
}


.feature8{
    .list-block{
        li{
            font-weight: 400;
            color:$dark;
        }
    }
}

/*******************
Feature 29
*******************/
.wrap-feature-29{
    margin-top: 60px;
    .title, .subtitle{
        color:$white;
    }
    .col-lg-4{
        margin-top: 30px;
    }
}
/*******************
Client
*******************/


    .client-box {
        margin: 0px;
        [class*="col-"] {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .testimonial10 {
        .testi10{
            .quote-bg{
                max-width: 800px;
                width: 100%;
                margin: 0 auto;
                padding: 60px;
                background: url(/img/up-quote.png) no-repeat top left, url(/img/down-quote.png) no-repeat bottom right;
            }
            h3{
                line-height: 40px;
            }
            .customer-thumb{
                margin: 15px 0;
                img{
                    width: 60px;
                }
            }
        }

    }

    @media(max-width:767px) {
       .testimonial10 {
           .testi10 h3{
                line-height: 30px;
               font-size:20px;
            }
        }
    }



/*******************
Blog homepage 1
*******************/

  .blog-home1 {
      [class*=col-] {
          transition: 0.2s ease-in;
          &:hover {
              transform: translateY(-10px);
          }
      }
  }

  .static-slider1 {
    padding: 50px 0 20px; }

    .case-listing .caser {
        border: none;
        padding: 15px 30px 15px 0
    }

  @media (max-width: 767px) {
    .static-slider1 {
        padding: 40px 0 20px; }
    .static-slider1 h1 {
      font-weight: 700;
      line-height: 36px;
      font-size: 24px; }
      .case-listing .caser {
        border: none;
        padding: 15px 0 15px 0
    }
      .case-listing .caser .card-block {
        border: none;
        text-align: center;
        padding: 15px 15px 15px 15px
    }
    .case-listing .caser .case-link {
       margin: 15px 0 0 0;
    }

}




