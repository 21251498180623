@media(max-width:991px) {
    .home-welcome {
        h2 {
            text-align: center;
            font-size: 26px;
            line-height: 1.2;
        }
        .text-inverse {
            text-align: center;
        }
        .peter-cite {
            text-align: center;
            font-size: 12px;
        }
    }
}
