
.team {text-align: center;
    .pro-pic{

        img{
            border-radius: 100%;
        }
    }
    ul{
        margin-top: 30px;
        li a{
            color:$muted;
            padding-right:15px;
            transition: 0.1s ease-in;
            display: block;
            &:hover{

                transform: translate3d(0px, -5px, 0px);
            }

        }

    }
    .title{
        margin: 30px 0 10px 0;
    }
    .subtitle{
        margin: 0 0 20px 0;
        font-size: 16px;
    }
}
