#banner-search {
    input {
        line-height: 28px;
    }

    button {
        line-height: 30px;
    }
    input[type=text] {
        border-radius: 3px 0 0 3px;
        text-overflow: ellipsis;
    }
    button {
        border-radius: 0 3px 3px 0;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        border: none;
        &:hover {
            opacity: 0.8;
        }
    }
}



@media (max-width: 767px) {
    #banner-search {
        .col-sm-6 {
            margin-right: auto;
            margin-left: 0 !important;
        }
    }
}

@media (max-width: 428px) {
    #banner-search {
        padding-bottom: 50px;
    }
}

@media (max-width: 590px) {
    #banner-search {
        .form-data {
            padding: 0 15px;
        }
    }
}
